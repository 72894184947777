<template>
  <div class="d-flex flex-column white rounded pa-6 mb-4 mx-4">
    <v-textarea
      v-model="txtTitle"
      label="快来分享您的最新作品"
      background-color="grey lighten-4"
      outlined
      dense
      rows="3"
    />
    <upload-images
      v-if="needImgTypes.indexOf(currType) !== -1"
      ref="wdUpload"
      :config="imgConfig"
      @change="onUploadImagesChange"
    />
    <div
      v-if="needImgTypes.indexOf(currType) !== -1"
      class="co-w-full co-h24"
    />

    <v-row>
      <v-col
        cols="12"
        md="7"
        sm="12"
      >
        <v-btn-toggle
          v-model="currType"
          background-color="white"
          text
        >
          <v-btn
            :disabled="types[0].disabled"
            :small="smAndDown"
            active-class="primary white--text"
            :value="types[0].type"
            height="40"
            @click="onType(types[0], 0)"
          >
            <v-icon
              v-if="!smAndDown"
              left
              :class="[currType === types[0].type ? 'white--text' : '']"
            >
              {{ types[0].icon }}
            </v-icon>
            {{ types[0].text }}
          </v-btn>

          <v-btn
            :disabled="types[1].disabled"
            :small="smAndDown"
            active-class="primary white--text"
            :value="types[1].type"
            height="40"
            @click="onType(types[1], 1)"
          >
            <v-icon
              v-if="!smAndDown"
              left
              :class="[currType === types[1].type ? 'white--text' : '']"
            >
              {{ types[1].icon }}
            </v-icon>
            {{ types[1].text }}
          </v-btn>

          <v-menu
            top
            offset-x
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-btn
                :disabled="types[2].disabled"
                :small="smAndDown"
                active-class="primary white--text"
                :value="types[2].type"
                height="40"
                v-bind="attrs"
                v-on="on"
                @click="onType(types[2], 2)"
              >
                <v-icon
                  v-if="!smAndDown"
                  left
                  :class="[currType === types[2].type ? 'white--text' : '']"
                >
                  {{ types[2].icon }}
                </v-icon>
                {{ types[2].text }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(pdItem, pdIndex) in pdItems"
                :key="pdIndex"
                @click="onPoundItem(pdIndex, pdItem)"
              >
                <v-list-item-title>{{ pdItem.label }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            :disabled="types[3].disabled"
            :small="smAndDown"
            active-class="primary white--text"
            :value="types[3].type"
            height="40"
            @click="onType(types[3], 3)"
          >
            <v-icon
              v-if="!smAndDown"
              left
              :class="[currType === types[3].type ? 'white--text' : '']"
            >
              {{ types[3].icon }}
            </v-icon>
            {{ types[3].text }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col
        cols="12"
        md="5"
        sm="12"
      >
        <div class="co-flex-row co-justify-end co-items-center">
          <pop-menu
            :iconed="btnScope.iconed"
            :texted="btnScope.texted"
            :text="btnScope.text"
            :prefix-icon="btnScope.prefixIcon"
            :actions="btnScope.items"
            @action="changeScope"
          />
          <v-btn
            :loading="btnIssue.loading"
            color="primary"
            class="ml-4"
            width="80"
            @click="onSubmit"
          >
            {{ btnIssue.text }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- <div class="co-flex-row co-justify-between co-items-center">
    </div> -->
    <dialog-alert ref="dlgAlert" />
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'
  import user from '@/api/co.user'

  const CntTypes = web.comm.ContentTypes

  export default {
    name: 'SectionIssueCenterEdit',
    components: {
      UploadImages: () => import('@/pages/sections/my/UploadImages.vue'),
      PopMenu: () => import('@/pages/sections/comm/PopMenu.vue'),
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    data () {
      return {
        txtTitle: '',
        currType: '',
        imgConfig: {
          ratio: 1,
          numberPerRow: 5,
          dense: true,
          limit: 9
        },
        imgCount: 0,
        upImages: [],
        detail: {},
        userInfo: {},
        types: [{
          icon: 'mdi-image-outline',
          text: '图片',
          type: CntTypes.IMAGE,
          disabled: false
        }, {
          icon: 'mdi-video-outline',
          text: '视频',
          type: CntTypes.VIDEO,
          disabled: false
        }, {
          icon: 'mdi-pound',
          text: '话题',
          type: CntTypes.POUND,
          disabled: false
        }, {
          icon: 'mdi-file-document-outline',
          text: '文章',
          type: CntTypes.ARTICLE,
          disabled: false
        }],
        btnScope: {
          icon: 'mdi-chevron-down',
          text: '公开',
          value: '',
          prefixIcon: '',
          iconed: false,
          texted: true,
          items: []
        },
        btnIssue: {
          loading: false,
          text: '发表'
        },
        needImgTypes: [
          CntTypes.IMAGE,
          CntTypes.POUND
        ],
        handlers: {
          [CntTypes.IMAGE]: this.toImage,
          [CntTypes.VIDEO]: this.toVideo,
          [CntTypes.POUND]: this.toPound,
          [CntTypes.ARTICLE]: this.toArticle
        },
        pdParams: null,
        pdItems: []
      }
    },
    computed: {
      mdAndDown () {
        return this.$vuetify.breakpoint.mdAndDown
      },
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      }
    },
    created () {
      this.userInfo = user.info.get()
      this.configScopes()
    },
    methods: {
      showAlert (type, message) {
        const dlg = this.$refs.dlgAlert
        if (dlg) {
          dlg.show({
            type,
            message
          })
        }
      },
      onPoundItem (index, item) {
        this.txtTitle = item.label
      },
      toImage (item) {
      },
      toVideo (item) {
        api.page.navigate(this, 'issueVideo')
      },
      toPound (item) {
        this.getPounds()
      },
      toArticle (item) {
        api.page.navigate(this, 'issueArticle')
      },
      onType (item, index) {
        const handler = this.handlers[item.type]
        if (typeof handler === 'function') {
          handler(item)
        }
      },
      setScope (item) {
        this.btnScope.text = item.text
        this.btnScope.value = item.value
        this.btnScope.prefixIcon = item.icon
      },
      changeScope (ev) {
        this.setScope(ev.item)
      },
      configScopes () {
        this.btnScope.items = []
        const descs = web.comm.ScopeDescs
        for (const key in descs) {
          const item = descs[key]
          this.btnScope.items.push(item)
        }
        const first = this.btnScope.items[0]
        this.setScope(first)
      },
      uploadFiles () {
        const frm = this.$refs.wdUpload
        if (frm) {
          frm.submit()
        }
      },
      reset () {
        const frm = this.$refs.wdUpload
        if (frm) {
          frm.clear()
        }
        this.upImages = []
        this.txtTitle = ''
      },
      checkWidget () {
        const result = []
        if (!this.txtTitle) {
          result.push('填写的作品标题')
        }

        if (result.length) {
          return result.join('，')
        } else {
          return ''
        }
      },
      onSubmit () {
        // if (!this.userInfo.mobile) {
        //   const me = this
        //   this.$confirm(
        //     '需要完善个人信息后才能发表作品。',
        //     '信息完善提示', {
        //       distinguishCancelAndClose: true,
        //       confirmButtonText: '去完善',
        //       cancelButtonText: '暂不'
        //     }
        //   )
        //     .then(() => {
        //       api.page.navigate(me, 'certificate')
        //     })
        //     .catch(action => {})
        // } else {
        //   const message = this.checkWidget()
        //   if (message) {
        //     this.$alert(message, '错误提醒', {
        //       confirmButtonText: '确定'
        //     })
        //   } else {
        //     this.btnIssue.loading = true
        //     if (this.upImages.length) {
        //       this.uploadFiles()
        //     } else {
        //       this.issueContent()
        //     }
        //   }
        // }

        const message = this.checkWidget()
        if (message) {
          this.$alert(message, '错误提醒', {
            confirmButtonText: '确定'
          })
        } else {
          this.btnIssue.loading = true
          if (this.upImages.length) {
            this.uploadFiles()
          } else {
            this.issueContent()
          }
        }
      },
      getTitle () {
        const result = api.comm.trim(this.txtTitle)
        return result
      },
      getTalkTopic (title) {
        const result = []
        const src = title.replace(/＃/g, '#')
        const arr = web.comm.parseTitleEx(src)
        for (const i in arr) {
          const item = arr[i]
          if (item.isPound) {
            const dst = item.title.replace(/#/g, '')
            result.push(dst)
          }
        }
        return result.join(';')
      },
      getData () {
        const thumbs = []

        for (const i in this.upImages) {
          const obj = this.upImages[i]
          const resp = obj.response || {}
          const data = resp.data || {}
          if (data.fileUrl) {
            thumbs.push(data.fileUrl)
          }
        }

        const cover = thumbs[0] || ''
        const scope = this.btnScope.value
        const title = api.comm.trim(this.txtTitle)
        const talkTopic = this.getTalkTopic(title)
        const result = {
          title,
          cover,
          type: this.currType || 'image',
          talkTopic,
          state: web.comm.States.PUBLISH,
          accessScope: scope || web.comm.Scopes.PUBLIC,
          thumbs: JSON.stringify(thumbs)
        }

        if (this.currType === CntTypes.POUND) {
          result.type = 'image'
        }

        if (thumbs.length < 1) {
          result.type = web.comm.ContentTypes.ARTICLE
          result.content = ''
        }

        return result
      },
      triggerIssueSuccess () {
        this.$emit('issue-success', this.detail)
      },
      issueContent () {
        const me = this
        const data = this.getData()
        const executed = function (res) {
          me.btnIssue.loading = false
          if (res.status) {
            me.detail = web.content.convertItem(res.data)
            me.detail.user = me.userInfo

            me.triggerIssueSuccess()

            me.showAlert('success', '发表成功')
            // me.$alert('本次作品发表成功，点击确认后继续发表。', '发表成功', {
            //   confirmButtonText: '确定',
            //   callback: action => {
            //     me.reset()
            //   }
            // })
            me.reset()
          } else {
            const err = res.data || { code: 9000, desc: '未知错误' }
            me.showAlert('error', `${err.code}-${err.desc}`)
            // const message = `错误信息：${res.data.code} - ${res.data.desc}`

            // me.$alert(message, '作品发表失败', {
            //   confirmButtonText: '确定'
            // })
          }
        }

        web.content.create({
          data,
          executed
        })
      },
      checkUploading () {
        const total = this.upImages.length
        let ready = 0
        let success = 0
        let error = 0
        for (const i in this.upImages) {
          const src = this.upImages[i]
          if (src.status === 'success') {
            success += 1
          } else if (src.status === 'ready') {
            ready += 1
          } else {
            error += 1
          }
        }

        return {
          ready, success, error, total
        }
      },
      checkIssue () {
        const result = this.checkUploading()
        if (result.ready < 1) {
          this.issueContent()
        }
      },
      onUploadImagesChange (ev) {
        // console.log({ ev })
        this.imgCount = ev.count
        this.upImages = ev.items

        this.checkIssue()
      },
      getPounds () {
        // const me = this
        if (!this.pdParams) {
          const executed = function (res) {
            // console.log('me.pdItems: %o', me.pdItems)
            if (res.status) {
            }
          }

          this.pdParams = web.dict.getParams({
            enabled: 'enabled',
            type: 'pound',
            pageSize: '8',
            sort: 'sort',
            order: 'desc',
            caches: this.pdItems,
            executed
          })
        }

        this.pdParams.reload = true
        api.httpx.getItems(this.pdParams)
      },
    }
  }
</script>
